/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
 export const formatPrice = (price) => {
    if (price) {
        const priceNum = parseFloat(price);
        return priceNum.toLocaleString();
    }
    return ''
}