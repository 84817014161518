let facetFilters = '';
let sortBys = '';
let offset = 0;
let query = '';
let verticalViewAllUsed = false;
let alternativeVerticalSuggestionUsed = false;
let verticalTabNavigationUsed = false;

const SearchSource = {
  Host: 'host',
  SearchInput: 'search_input',
  FacetFilter: 'facet_filter',
  SortBy: 'sort_by',
  Paginate: 'paginate',
  VerticalViewAll: 'vertical_view_all',
  AlternativeVerticalSuggestion: 'alternative_vertical_suggestion',
  VerticalTabNavigation: 'vertical_tab_navigation'
}

/**
 * compares the path between to the current page and document.referrer to see if a new vertical
 * navigation has taken place. The check to ensure the origins are the same is there because
 * when a page is initially iframed, the referrer is actually the parent page. This check should
 * only be applicable in subsequent navigations within the iframe.
 */
const isNewVertical = () => {
  const referrerUrl = new URL(document.referrer);
  const currentUrl = new URL(window.location.href);

  const isSameOrigin = referrerUrl.origin === currentUrl.origin;
  const pathsAreDifferent = referrerUrl.pathname !== currentUrl.pathname;

  return isSameOrigin && pathsAreDifferent;
}

/**
 * Helper function to produce consistent facet filter strings. Answers sometimes populates the
 * facetFilters query param with objects with empty properties even though there are no facets.
 */
const getFacetFilters = params => {
  const facetFiltersStr = params.get('facetFilters');
  const facetFiltersJson = JSON.parse(facetFiltersStr);

  for (const key in facetFiltersJson) {
    if (facetFiltersJson[key].length) {
      return facetFiltersStr;
    }
  }

  return '{}';
}

/**
 * Get initial values for the search query, facet filters, sort bys, and offset.
 * These values will be compared in future searches to determine what kind of
 * user action triggered the search.
 */
export const initSearchParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  query = params.get('query') || '';
  facetFilters = getFacetFilters(params);
  sortBys = params.get('sortBys') || '[]';
  offset = params.get('search-offset') || '0';
}

/**
 * Dysons wants to know which user action was taken to produce the current search.
 * Upon a new search, we compute the new values for the search query, facet filters,
 * sort bys, and offset. since each of these user actions produces a new search, only
 * one of these new values will be different from the previously computed value. That
 * determines which action was taken.
 */
const getSearchSource = (resource) => {
  const url = new URL(resource);
  const params = new URLSearchParams(url.search);

  const newQuery = params.get('input');
  const newFacetFilters = getFacetFilters(params);
  const newSortBys = params.get('sortBys') || '[]';
  const newOffset = params.get('offset') || '0';

  const iframeUrlParams = new URL(window.location.href).searchParams;

  let searchSource = SearchSource.Host;
  if (isNewVertical() && !verticalTabNavigationUsed) {
    searchSource = SearchSource.VerticalTabNavigation;
    verticalTabNavigationUsed = true;
  } else if (iframeUrlParams.has(SearchSource.AlternativeVerticalSuggestion) && !alternativeVerticalSuggestionUsed) {
    searchSource = SearchSource.AlternativeVerticalSuggestion;
    alternativeVerticalSuggestionUsed = true;
  } else if (iframeUrlParams.has(SearchSource.VerticalViewAll) && !verticalViewAllUsed) {
    searchSource = SearchSource.VerticalViewAll;
    verticalViewAllUsed = true;
  } else if (query != newQuery) {
    searchSource = SearchSource.SearchInput;
    query = newQuery;
  } else if (facetFilters != newFacetFilters) {
    searchSource = SearchSource.FacetFilter;
    facetFilters = newFacetFilters;
  } else if (sortBys != newSortBys) {
    searchSource = SearchSource.SortBy;
    sortBys = newSortBys;
  } else if (offset != newOffset) {
    searchSource = SearchSource.Paginate;
    offset = newOffset;
  }

  return searchSource;
}

export const buildAndPostMessage = async (resource, response) => {
  if (resource.indexOf('liveapi.yext.com') == -1) {
    return;
  }
  const data = await response.json();
  const activeVertical = document.querySelector('.js-yxt-navItem.is-active');
  const activeVerticalName = activeVertical ? activeVertical.innerText.trim() : '';

  const searchSource = getSearchSource(resource);
  let resultsCount = 0;
  if (data.response.resultsCount) {
    resultsCount = data.response.resultsCount;
  } else if (data.response.modules?.length) {
    data.response.modules.forEach( module => {
      resultsCount += module.resultsCount;
    });
  }

  const messageToPost = {
    eventType: 'search_results_load_end',
    searchCategory: activeVerticalName.toLowerCase(),
    noOfResults: resultsCount || 'no_results',
    searchTerm: query,
    searchSource
  }

  if (searchSource === SearchSource.FacetFilter) {
    messageToPost.facetFilters = facetFilters;
  } else if (searchSource === SearchSource.SortBy) {
    messageToPost.sortBys = sortBys;
  }

  window.parent.postMessage(messageToPost, '*');
}

export const appendSourceDescriptionParams = () => {
  document.querySelectorAll('a').forEach(link => {
    if (link.href) {
      const url = new URL(link.href);
      if (url.host === 'www.dyson.com' && !url.searchParams.has('source_description') && link.dataset.eventoptions) {
        const eventOptions = JSON.parse(link.dataset.eventoptions);
        url.searchParams.append('source_description', `yext~${eventOptions.verticalKey}~${eventOptions.entityId}`);
        link.href = url.toString();
      }
    }
  })
}

export const appliedFilterTemplate = () => {
  return `{{#if appliedFiltersArray.length}}
  <div class="yxt-AppliedFilters" aria-label="{{_config.labelText}}">
    {{#each appliedFiltersArray}}
      {{#if ../_config.showFieldNames}}
        <div class="yxt-AppliedFilters-filterLabel">
          <span class="yxt-AppliedFilters-filterLabelText">{{this.label}}</span>
          <span class="yxt-AppliedFilters-filterLabelColon">:</span>
        </div>
      {{/if}}
      {{#each filterDataArray}}
        {{#if removable}}
          <button class="yxt-AppliedFilters-removableFilterTag js-yxt-AppliedFilters-removableFilterTag"
            data-filter-id={{dataFilterId}} tabindex="0">
            <span class="yxt-AppliedFilters-removableFilterValue">{{displayValue}}</span>
            <span class="sr-only"> remove </span>
            <span class="yxt-AppliedFilters-removableFilterX" aria-hidden="true">&times;</span>
          </button>
        {{else}}
          <div class="yxt-AppliedFilters-filterValue">
            <span class="yxt-AppliedFilters-filterValueText">{{displayValue}}</span>
            {{#unless @last}}<span class="yxt-AppliedFilters-filterValueComma">,</span>{{/unless}}
          </div>
        {{/if}}
      {{/each}}
      {{#unless @last}}
        <div class="yxt-AppliedFilters-filterSeparator">{{../_config.delimiter}}</div>
      {{/unless}}
    {{/each}}
  </div>
  {{/if}}
`;
}
